

@media (max-width: 746px) {
    .create-proposal input {
        width: 40vw;
    }

    .create-proposal-string {
        width: 40vw;
    }

    .create-proposal-address {
        width:75vw;
    }

    .create-proposal-numeric {
        width:20vw;
    }
}